<template>
	<select
		:value="value"
		class="custom-select"
		@input="selectInputHandler"
		@change="$emit('change', $event.target.value)"
	>
		<option
			v-for="(item, index) in optionList"
			:key="index"
			:value="item.value"
		>
			<slot name="option" :item="item"></slot>
		</option>
	</select>
</template>
<script>
export default {
	props: {
		value: {
			default: ""
		},
		optionList: {
			type: Array
		}
	},
	methods: {
		selectInputHandler($event) {
			const val = $event.target.value;
			this.$emit("input", val);
		}
	}
};
</script>
<style lang="scss" scoped></style>
